import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import Tweet from "../../components/Tweet";
import CaptionedImage from "../../components/CaptionedImage";
import FlowGrid from '../../components/FlowGrid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I've been talking a fair amount recently about how I've been writing a lot of unit tests for `}<a parentName="p" {...{
        "href": "https://getfluency.io"
      }}>{`Fluency, my Confluence editor app`}</a>{`, and how much I value having good tests to catch regressions and verify that the editor is actually working correctly. In the case of Fluency, I now have 70+ tests which test various parts of the app, and I've extracted various utils out of the actual `}<inlineCode parentName="p">{`XCTestCase`}</inlineCode>{`s into functions like `}<inlineCode parentName="p">{`assertRoundTrip(xml:)`}</inlineCode>{`, which takes in `}<a parentName="p" {...{
        "href": "https://confluence.atlassian.com/doc/confluence-storage-format-790796544.html"
      }}>{`Confluence Storage Format`}</a>{` XML, renders it to an `}<inlineCode parentName="p">{`NSAttributedString`}</inlineCode>{`, reads it back, and asserts that the resulting xml is the same as what was passed in - a crucial test when you want to make sure your editor isn't going to break any page storage just by saving.`}</p>
    <CaptionedImage filename="xcodeshared-1.png" max={300} caption="" alt="Xcode UI showing RoundTripRenderingTest from Fluency in the test navigator" mdxType="CaptionedImage" />
    <p>{`Today, I took the next step by writing some more complicated tests: I wanted to sample 100+ valid Confluence pages from various places on the web and `}<inlineCode parentName="p">{`assertRoundTrip`}</inlineCode>{` on them, to give myself a reasonable amount of confidence that my editor could handle "real world" inputs. Some of these pages were quite large, and as a result the tests would take a long time to run, so I opted to put them in a separate test target so that I could run all the "unit" tests separately from the "integration" tests.`}</p>
    <p>{`This all seemed fine, but I ran into a road block - `}<inlineCode parentName="p">{`assertRoundTrip`}</inlineCode>{` had been declared in my original test target, so it wasn't available in the new one. After struggling for a few hours, I was finally  (with some help) able to figure out a way to extract my test utils into a shared library, but it wasn't at all straightforward. In this post I'll describe the method I used so that you can implement the same thing if it works for your project.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The sample code for this article is available at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSharedTestUtilsTargetExample"
        }}>{`NGSharedTestUtilsTargetExample`}</a>{`. If you're just interested in a list of steps to create the shared library, skip to `}<a parentName="p" {...{
          "href": "/blog/xcode-shared-test-target#tldr"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "background-and-goal"
    }}>{`Background and Goal`}</h2>
    <p>{`Let's assume a fairly standard setup for iOS app development: we have an app called `}<inlineCode parentName="p">{`ExampleApp`}</inlineCode>{`, and if we checked the "Include Unit Tests" checkbox when we created it in Xcode, we have a unit test target called `}<inlineCode parentName="p">{`ExampleAppTests`}</inlineCode>{`. For purposes of example, let's say we have a container type in our app:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Container`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` value1: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` value2: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And in our tests, we've defined a util to test it (`}<inlineCode parentName="p">{`assertContainerWorks`}</inlineCode>{` is less complicated than `}<inlineCode parentName="p">{`assertRoundTrip`}</inlineCode>{`, but the same idea):`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ExampleAppTests.swift`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`XCTest`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@testable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleApp`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`assertContainerWorks`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` container = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Container`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`value1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"abc"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`value2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"abc"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`XCTAssertEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(container.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, container.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleAppTests`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: XCTestCase {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testExample`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`throws`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`assertContainerWorks`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now, we want to add another test target which uses the same util. Right-clicking ExampleAppTests in the text navigator and choosing "New Unit Test Target" gets us that target, which we can call `}<inlineCode parentName="p">{`OtherTests`}</inlineCode>{`:`}</p>
    <CaptionedImage filename="xcodeshared-2.png" max={400} caption="" alt="Xcode UI with 'New Unit Test' menu item selected" mdxType="CaptionedImage" />
    <p>{`Our `}<inlineCode parentName="p">{`OtherTests.swift`}</inlineCode>{` looks like:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`XCTest`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@testable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleApp`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`OtherTests`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: XCTestCase {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testExample`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`throws`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`assertContainerWorks`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`But the issue is that this doesn't compile, since `}<inlineCode parentName="p">{`assertContainerWorks`}</inlineCode>{` is defined in `}<inlineCode parentName="p">{`ExampleAppTests`}</inlineCode>{`, not `}<inlineCode parentName="p">{`OtherTests`}</inlineCode>{`.`}</p>
    <p>{`Our goal is to extract `}<inlineCode parentName="p">{`assertContainerWorks`}</inlineCode>{` into a shared library (we'll call it `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{`) such that:`}</p>
    <ol>
      <li parentName="ol">{`Both test targets should be able to import it and use `}<inlineCode parentName="li">{`assertContainerWorks`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`SharedTestUtils`}</inlineCode>{` itself should be able to use types from `}<inlineCode parentName="li">{`ExampleApp`}</inlineCode>{` (i.e. it should be able to `}<inlineCode parentName="li">{`@testable import ExampleApp`}</inlineCode>{`)`}</li>
    </ol>
    <h2 {...{
      "id": "creating-a-static-library"
    }}>{`Creating a Static Library`}</h2>
    <blockquote>
      <p parentName="blockquote">{`If you'd like to practice this part or compare it to your own local Xcode setup, `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGSharedTestUtilsTargetExample/commit/d8fb23b14168561000189a71533e79747ade16f3"
        }}>{`this commit`}</a>{` is the starting point before the shared library has been created.`}</p>
    </blockquote>
    <p>{`The way we'll approach this is to create `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` as a static library (for more on why we need a static library as opposed to another kind of library, see the next section). The first step is to create the new target:`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="xcodeshared-3.png" alt="Xcode menu showing the 'New Target' option selected" caption="New Target" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="xcodeshared-4.png" alt="Xcode menu showing the 'Static Library' option selected" caption="Static library" mdxType="CaptionedImage" />
    </FlowGrid>
    <p><inlineCode parentName="p">{`SharedTestUtils.swift`}</inlineCode>{` gets created automatically - we can fill it in with our util (and since it's now in another module, it has to be `}<inlineCode parentName="p">{`public`}</inlineCode>{`):`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// SharedTestUtils.swift`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`XCTest`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@testable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleApp`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`public`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`assertContainerWorks`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` container = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`Container`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`value1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"abc"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`value2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"abc"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`XCTAssertEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(container.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, container.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`value2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now let's go to work by telling Xcode to run the tests:`}</p>
    <CaptionedImage filename="xcodeshared-5.png" max={400} caption="" alt="Xcode UI with the 'Run example app tests' menu item selected" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "compiling-the-shared-library"
    }}>{`Compiling the shared library`}</h2>
    <p>{`(This section goes into detail about how to triage and resolve each issue I ran into - I'm hoping this is helpful for folks who, like me, weren't experienced at building and linking static libraries. If you're looking for a laundry list of what to do to make it work, skip to `}<a parentName="p" {...{
        "href": "/blog/xcode-shared-test-target#tldr"
      }}>{`here`}</a>{`.)`}</p>
    <h3 {...{
      "id": "clean-and-run-1"
    }}>{`Clean and run #1:`}</h3>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Use of unresolved identifier 'assertContainerWorks'`}</span></code></pre>
    <p>{`Makes sense - now that we have `}<inlineCode parentName="p">{`assertContainerWorks`}</inlineCode>{` in a separate library, we need to import the library's Swift module. `}<inlineCode parentName="p">{`import SharedTestUtils`}</inlineCode>{` works, so our tests now look like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`XCTest`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@testable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleApp`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk15"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`SharedTestUtils`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`ExampleAppTests`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: XCTestCase {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testExample`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`throws`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`assertContainerWorks`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <h3 {...{
      "id": "clean-and-run-2"
    }}>{`Clean and run #2:`}</h3>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`No such module 'SharedTestUtils'`}</span></code></pre>
    <p>{`We haven't told Xcode that our test targets depend on `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` being built, so it hasn't built it for us. We'll need to add `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` as a dependency in the Xcode project settings (Build Phrases section) for both test targets:`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="xcodeshared-6.png" alt="Xcode menu showing the plus button in the dependencies menu" caption="New Dependency" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="xcodeshared-7.png" alt="Xcode menu showing the 'SharedTestUtils' library being selected as a dependency" caption="SharedTestUtils" mdxType="CaptionedImage" />
    </FlowGrid>
    <h3 {...{
      "id": "clean-and-run-3"
    }}>{`Clean and run #3:`}</h3>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Command CompileSwift failed with a nonzero exit code`}</span></code></pre>
    <p>{`This one is a little harder: looking at the build log in the report navigator, we see the real error: `}<inlineCode parentName="p">{`Failed to load module 'XCTest'`}</inlineCode>{`.`}</p>
    <CaptionedImage filename="xcodeshared-8.png" max={600} caption="" alt="Xcode build output screen showing the failure message" mdxType="CaptionedImage" />
    <p>{`This is because we haven't linked the `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` library against `}<inlineCode parentName="p">{`XCTest`}</inlineCode>{`. We'll need to go to the project settings for `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{`, Build Phases, Link Binary With Libraries and select `}<inlineCode parentName="p">{`XCTest`}</inlineCode>{` from the sheet.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="xcodeshared-9.png" alt="Xcode menu showing the plus button in linking menu" caption="Linking Menu" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="xcodeshared-10.png" alt="Xcode menu showing the 'XCTest' library being selected for linking" caption="Selecting XCTest" mdxType="CaptionedImage" />
    </FlowGrid>
    <h3 {...{
      "id": "clean-and-run-4"
    }}>{`Clean and run #4:`}</h3>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`No such module 'ExampleApp'`}</span></code></pre>
    <p>{`We haven't specified that the `}<inlineCode parentName="p">{`ExampleApp`}</inlineCode>{` target is a dependency of `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{`, so we'll need to add that via Xcode settings as well.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="xcodeshared-11.png" alt="Xcode menu showing the plus button in the dependencies menu for SharedTestUtils" caption="" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="xcodeshared-12.png" alt="Xcode menu showing the 'XCTest' library being selected for linking" caption="" mdxType="CaptionedImage" />
    </FlowGrid>
    <h3 {...{
      "id": "clean-and-run-5"
    }}>{`Clean and run #5:`}</h3>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`Undefined symbol: SharedTestUtils.assertContainerWorks() -> ()`}</span></code></pre>
    <p>{`We've gotten past the compiler errors and we're now on to linker errors. `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` got compiled, but our test targets aren't linking against it, so the linker doesn't know where to find the executable code for `}<inlineCode parentName="p">{`assertContainerWorks`}</inlineCode>{`. This can be solved in Xcode project settings too - under "Link Binary with Libraries" for both test targets, we'll add `}<inlineCode parentName="p">{`libSharedTestUtils.a`}</inlineCode>{`, the static library artifact that results from compiling `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{`.`}</p>
    <FlowGrid columns={2} mdxType="FlowGrid">
    <CaptionedImage max={300} filename="xcodeshared-13.png" alt="Xcode menu showing the plus button in the linking menu for the test target" caption="Adding a library to link against" mdxType="CaptionedImage" />
    <CaptionedImage max={300} filename="xcodeshared-14.png" alt="Xcode menu showing the 'libSharedTestUtils.a' library being selected for linking" caption="libSharedTestUtils.a" mdxType="CaptionedImage" />
    </FlowGrid>
    <h3 {...{
      "id": "clean-and-run-6"
    }}>{`Clean and run #6:`}</h3>
    <p>{`It works!`}</p>
    <CaptionedImage max={400} filename="xcodeshared-15.png" alt="Xcode test run UI showing tests passing" caption="🎉" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "tldr"
    }}>{`tl;dr:`}</h2>
    <p>{`In order to create a library that depends on app code and is shared between two test targets, you need to:`}</p>
    <ol>
      <li parentName="ol">{`Create the library as a Static Library target`}</li>
      <li parentName="ol">{`Link the library with XCTest`}</li>
      <li parentName="ol">{`Add your app target as a dependency of the library target`}</li>
      <li parentName="ol">{`Add the library target as a dependency as all test targets and link all test targets with the `}<inlineCode parentName="li">{`.a`}</inlineCode>{` binary`}</li>
      <li parentName="ol">{`Import the library into your tests`}</li>
    </ol>
    <h2 {...{
      "id": "other-options-considered"
    }}>{`Other Options Considered`}</h2>
    <p>{`A static library is only one way to include code in a dependency. I also tried using a Unit Test Bundle, but ran into linker issues - the test targets can't link against `}<inlineCode parentName="p">{`SharedTestUtils`}</inlineCode>{` if it's a Unit Test Bundle, or at least not easily - Xcode doesn't show it in the Link Binary With Libraries setting.`}</p>
    <p>{`Using a dynamic library (wrapped in a Framework target) is also an option, and it might work well if your utils library just needs `}<inlineCode parentName="p">{`XCTest`}</inlineCode>{` and doesn't need to `}<inlineCode parentName="p">{`@testable import`}</inlineCode>{` your app. However, this is subject to the same issue as a Unit Test Bundle, where you can't link against the app binary. Thanks to `}<a parentName="p" {...{
        "href": "https://twitter.com/NeoNacho/status/1292203466362773505"
      }}>{`Boris Bügling for pointing out`}</a>{` that you can get around this with `}<inlineCode parentName="p">{`BUNDLE_LOADER`}</inlineCode>{`, but it turns out if you specify the loader app correctly you end up with the following:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`'-bundle_loader <path/to/ExampleApp> not allowed with '-dynamiclib'`}</span></code></pre>
    <p>{`Apparently `}<inlineCode parentName="p">{`BUNDLE_LOADER`}</inlineCode>{` doesn't work with dynamic libraries, which is why I went with the static library approach in the first place. To be honest, I'm not sure if this is the best way - there could be something I'm totally missing that might allow this to work with a Framework. Please `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{` if you have ideas 👋`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`Hopefully this approach helps folks who want to maintain multiple unit test targets while keeping shared app test utils in common between them. It's worked great in `}<a parentName="p" {...{
        "href": "https://getfluency.io"
      }}>{`Fluency`}</a>{`, but your mileage might vary - if you're interested in discussing this or other Xcode/Swift/iOS related things, you can `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`follow me on Twitter`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      